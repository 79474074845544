import React from 'react';
import GithubIcon from '../svg/github.svg';
import TwitterIcon from '../svg/twitter.svg';
import LinkedinIcon from '../svg/linkedin.svg';
import EmailIcon from '../svg/email.svg';
import styled from 'styled-components';

const Container = styled.section`
  padding: 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  min-width: 300px;
  max-width: 480px;
  width: 90vw;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Body = styled.article`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;

  p:nth-of-type(3) {
    padding-top: 1rem;
  }

  p:nth-of-type(1),
  p:nth-of-type(2) {
    span,
    a,
    a:hover {
      color: rgba(255, 102, 0, 0.7);
      text-decoration: none;
    }
    a:focus {
      outline: 1px solid rgba(255, 102, 0, 0.5);
    }
    a:after {
      content: '→';
      padding-left: 0.125rem;
      display: inline-block;
    }
  }
`;

const Header = styled.h1`
  margin: 0 auto;
  text-align: center;
  width: 100%;
  font-size: 2.5em;
  padding: 1rem 1rem;
  min-height: 1rem;
`;

const Paragraph = styled.p`
  padding: 0 2rem;
  margin: 0 0 0.5rem 0;
`;

const about = () => (
  <Container>
    <Body>
      <Header>Joey van Dijk</Header>
      <Paragraph>
        I am a Dutch Javascript developer for hire @{' '}
        <a href="http://www.sayyup.nl">Say Yup</a> based in the Netherlands.
      </Paragraph>
      <Paragraph>
        I have worked at{' '}
        <a href="http://www.techtribe.nl" className="company">
          Techtribe
        </a>{' '}
        (part of the{' '}
        <a href="http://www.makerstreet.nl" className="company">
          Makerstreet
        </a>{' '}
        network), DigitasLBi,{' '}
        <a href="https://www.mediamonks.com/">MediaMonks</a>,{' '}
        <span>Park to Play</span>, <a href="https://www.rtl.nl">RTL</a>,{' '}
        <a href="https://www.schiphol.nl">Schiphol</a>,{' '}
        <span>Scotch & Soda</span>,{' '}
        <a href="https://ddbunlimited.nl">Tribal DDB Amsterdam</a>,{' '}
        <span>Vakantieveilingen</span>,{' '}
        <a href="https://www.zilverenkruis.nl/">Zilveren Kruis</a> and started
        at <span>Basic Concepts</span>.
      </Paragraph>
      <Paragraph>
        You can contact me by{' '}
        <a
          href="https://github.com/joeyvandijk"
          rel="noopener"
          aria-label="Github"
        >
          <GithubIcon
            className="icon-svg"
            width="20px"
            height="20px"
            role="img"
            aria-hidden="true"
            // accessibility: useful for preventing double tabbing
            focusable="false"
          />
        </a>
        <a
          href="https://twitter.com/joeyvandijk"
          rel="noopener"
          aria-label="Twitter"
        >
          <TwitterIcon
            className="icon-svg"
            width="20px"
            height="20px"
            role="img"
            aria-hidden="true"
            // accessibility: useful for preventing double tabbing
            focusable="false"
          />
        </a>
        <a
          href="https://www.linkedin.com/in/joeyvandijk"
          rel="noopener"
          aria-label="Linkedin"
        >
          <LinkedinIcon
            className="icon-svg"
            width="20px"
            height="20px"
            role="img"
            aria-hidden="true"
            // accessibility: useful for preventing double tabbing
            focusable="false"
          />
        </a>
        <a href="mailto:info@joeyvandijk.nl" rel="noopener" aria-label="E-mail">
          <EmailIcon
            className="icon-svg"
            width="20px"
            height="20px"
            role="img"
            aria-hidden="true"
            // accessibility: useful for preventing double tabbing
            focusable="false"
          />
        </a>
        .
      </Paragraph>
    </Body>
  </Container>
);
export default about;
