import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  padding: 0;
  border: 2px solid #fff;
  margin: 0 auto;
  min-width: 200px;
  max-width: 400px;
  width: 80vw;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4rem;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Body = styled.article`
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
`;

const Header = styled.h1`
  margin: 0 0 1rem 0;
  text-align: center;
  width: 100%;
  font-size: 2.5em;
  padding: 1rem 1rem;
  min-height: 1rem;
  border-bottom: 2px solid #fff;
`;

const Paragraph = styled.p`
  padding: 0 2rem;
  margin: 0 0 0.5rem 0;
  text-align: center;
`;

const error = (props) => (
  <Container>
    <Body>
      <Header>Error</Header>
      <Paragraph>{props.title}</Paragraph>
    </Body>
  </Container>
);
export default error;
