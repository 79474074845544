// TODO: update effect, too often sharp circles
export default function drawEffect(ctx, color, dimensions) {
  ctx.beginPath();
  // > x0,y0,r0,x1,y1,r1
  var rgr = ctx.createRadialGradient(
    dimensions.x,
    dimensions.y,
    dimensions.radius.start,
    dimensions.x,
    dimensions.y,
    dimensions.radius.end
  );
  rgr.addColorStop(
    0,
    'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.alpha + ')'
  );
  var rgb = {
    r: color.r,
    g: color.g,
    b: color.b,
    alpha: 0,
  };
  var endColor = 100; // 0-255
  if (rgb.r > rgb.g) {
    rgb.r = endColor;
    rgb.g = 0;
  } else {
    rgb.r = 0;
    rgb.g = endColor;
  }
  if (rgb.g > rgb.b) {
    rgb.g = endColor;
    rgb.b = 0;
  } else {
    rgb.g = 0;
    rgb.b = endColor;
  }
  rgr.addColorStop(
    1,
    'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + rgb.alpha + ')'
  );
  ctx.fillStyle = rgr;

  const radius = dimensions.radius.end - dimensions.radius.start;
  const doubleRadius = radius * 2;
  ctx.fillRect(
    dimensions.x - radius,
    dimensions.y - radius,
    doubleRadius,
    doubleRadius
  );

  ctx.closePath();
}
