import { useState, useEffect } from 'react';
import useWindowEvent from './useWindowEvent';

function measurements() {
  if (typeof window !== `undefined`) {
    return {
      width: Math.max(
        window.innerWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      ),
      height: Math.max(
        window.innerHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      ),
    };
  }

  return {
    width: 1,
    height: 1,
  };
}

function resizeCanvas(canvas, wd, hg) {
  canvas.width = wd;
  canvas.height = hg;
}

export default function canvasSizes(canvas) {
  // directly used inside a component, but this is not best-practice
  /* eslint-disable react-hooks/rules-of-hooks */
  const [width, setWidth] = useState(1);
  const [height, setHeight] = useState(1);
  let timeout;

  const { width: wd, height: hg } = measurements();
  useWindowEvent('resize', () => {
    // bug only on Chrome on iOS: https://bugs.webkit.org/show_bug.cgi?id=170595
    timeout = setTimeout(() => {
      const { width: wd, height: hg } = measurements();
      setWidth(wd);
      setHeight(hg);
      resizeCanvas(canvas, wd, hg);
    }, 50);
  });
  /* eslint-enable react-hooks/rules-of-hooks */

  if (canvas && (width !== wd || height !== hg)) {
    setWidth(wd);
    setHeight(hg);
    resizeCanvas(canvas, wd, hg);
  }

  return {
    width,
    height,
  };
}
