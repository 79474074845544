import drawEffect from './drawEffect';
import randomColor from './randomColor';

function prepareDimensions(wd, hg) {
  // TODO: do not need to be radials? all kinds of shapes?
  const dimensions = { width: wd, height: hg, cells: {} };
  dimensions.cells.h = Math.ceil(wd / 300);
  dimensions.cells.v = Math.ceil(hg / 300);
  return dimensions;
}

function calculateRadius(dimensions) {
  var radius = Math.ceil(dimensions.width / dimensions.cells.h);
  var radiusVertical = Math.ceil(dimensions.height / dimensions.cells.v);
  return (radius = radiusVertical > radius ? radiusVertical : radius);
}
export default function drawGradient(canvas, wd, hg) {
  let now;
  let performanceCheck = true;
  let ctx;

  /* eslint-disable compat/compat */
  if (performanceCheck && typeof window !== `undefined` && window.performance) {
    now = window.performance.now();
  }
  /* eslint-enable compat/compat */

  if (canvas !== undefined && canvas.getContext !== undefined) {
    ctx = canvas.getContext('2d');

    // background
    ctx.fillStyle = '#000';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const dimensions = prepareDimensions(wd, hg);

    // TODO determine radius / number of cells / distance
    const radius = calculateRadius(dimensions);

    const il = dimensions.cells.h * dimensions.cells.v;
    const pos = { x: 0, y: 0 };

    const items = new Array(il).fill(0, 0, 1).fill(1, 1);
    items.map(() => {
      // randomize position
      var random = {
        x: Math.floor((Math.random() * radius * 2) / 3),
        y: Math.floor((Math.random() * radius * 2) / 3),
      };
      const px = pos.x + random.x;
      const py = pos.y + random.y;

      // adjust radius depending on randomized position
      const r = Math.round(radius * (1.7 + Math.random() * 3.1));

      // determine colors (vivid)
      const clr = randomColor();

      // draw
      drawEffect(
        ctx,
        { r: clr.r, g: clr.g, b: clr.b, alpha: clr.a },
        { x: px, y: py, radius: { start: 0, end: r } }
      );

      const nw = pos.x + radius;
      if (nw > dimensions.width) {
        pos.x = 0;
        pos.y += radius;
      } else {
        pos.x = px + radius;
      }
    });

    /* eslint-disable compat/compat */
    if (
      performanceCheck &&
      typeof window !== `undefined` &&
      window.performance
    ) {
      console.log('Ended in', window.performance.now() - now, 'ms');
    }
    /* eslint-enable compat/compat */
  }
}
