import React from 'react';
import Layout from '../components/layout';
import CanvasEffect from '../components/canvasEffect';
import About from '../components/about';
import SEO from '../components/seo';

// TODO: no javascript availabe what then?

/* eslint-disable compat/compat */
const Item = () => {
  if (typeof window === `undefined`) return null;

  return (
    <>
      <div
        id="1"
        style={{ zIndex: 100, top: 0, left: 0, position: 'absolute' }}
      >
        orientation:{' '}
        {window.screen.orientation ? window.screen.orientation.angle : -1} -{' '}
        {window.orientation}
      </div>
      <div
        id="2"
        style={{ zIndex: 100, top: 20, left: 0, position: 'absolute' }}
      >
        screen: {window.screen.width} x {window.screen.height}
      </div>
      <div
        id="3"
        style={{ zIndex: 100, top: 40, left: 0, position: 'absolute' }}
      >
        inner: {window.innerWidth} x {window.innerHeight}
      </div>
      <div
        id="4"
        style={{ zIndex: 100, top: 60, left: 0, position: 'absolute' }}
      >
        client: {document.documentElement.clientWidth} x{' '}
        {document.documentElement.clientHeight}
      </div>
      <div
        id="5"
        style={{ zIndex: 100, top: 80, left: 0, position: 'absolute' }}
      >
        rect:{' '}
        {document.querySelector('canvas')
          ? document.querySelector('canvas').getBoundingClientRect().width
          : -1}{' '}
        x{' '}
        {document.querySelector('canvas')
          ? document.querySelector('canvas').getBoundingClientRect().height
          : -1}
      </div>
    </>
  );
};
/* eslint-enable compat/compat */

const Page = (props) => (
  <>
    <Layout>
      <SEO keywords={[`Joey van Dijk`, `site`]} language="en" />
      <CanvasEffect />
      {/* <Item /> */}
      <About />
    </Layout>
  </>
);

export default Page;
