import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CanvasEffect from '../components/canvasEffect';
import Error from '../components/error';

// TODO: no javascript availabe what then?

const NotFoundPage = (props) => (
  <>
    <Layout>
      <SEO
        context={{
          title: 'Error: Not found',
          description: 'Missing page, try the homepage.',
        }}
        language="en"
      />
      <CanvasEffect />
      <Error title="Missing page, try the homepage." />
    </Layout>
  </>
);

export default NotFoundPage;
