import { createGlobalStyle } from 'styled-components';
import { reboot } from 'styled-reboot';

const GlobalStyle = createGlobalStyle`
    ${reboot}
    /* other styles */

    html,
    body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    font-size: 1em;
    min-height: 100%;
    }

    html {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 100%;
    }

    @media (min-width: 768px) {
    html {
        /* 18 pixels in most browsers */
        font-size: 112.5%;
    }
    }

    body {
    display: block;
    background-color: #fff;
    font-size: 1rem;
    color: #fff;
    }

    /* improve font rendering */
    a,
    p,
    h2,
    input,
    div {
    font-feature-settings: 'kern' 1;
    font-kerning: normal;
    -webkit-font-smoothing: auto;
    /* Chrome, Safari */
    -moz-osx-font-smoothing: auto;
    /* Firefox */
    }

    a,
    a:hover,
    a:active {
    color: black;
    cursor: pointer;
    }

    .icon-svg {
    margin: 0.5rem;
    }

    .icon-svg g g {
    fill: white;
    }

    a:hover .icon-svg g g {
    fill: #ff6600;
    }

    canvas {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    /* width: 100%; */
    /* height: 100%; */
    }

    div {
    width: 100%;
    height: 100%;
    }

    a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    }

    a:hover,
    a:active {
    color: #ff6600;
    }

    /* ############# ANIMATION ################# */

    canvas {
    opacity: 0;
    }

    canvas.off {
    animation: canvasHide 0.5s ease-in 1;
    /* check canvasEffect.js (timeout needs to be the same) */
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    }

    canvas.on {
    animation: canvasShow 1.1s ease-in 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    }

    canvas.hard {
    animation: canvasShow 0.2s ease-in 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    }

    @keyframes canvasHide {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
    }

    @keyframes canvasShow {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
    }

    /* ############# MEDIA QUERIES ################# */

    @media all and (min-height: 0px) and (max-height: 480px - 1) and (max-width: 480px - 1) {
    section {
        width: 85%;
        max-width: 480px;
    }
    }

    @media all and (min-height: 0px) and (max-height: 480px - 1) and (min-width: 480px) {
    /* width */

    section {
        width: 85%;
        max-width: 480px;
    }
    }

    @media all and (min-height: 480px) and (max-width: 480px - 1) {
    /* height */

    section {
        width: 85%;
        max-width: 480px;
    }
    }

    /* Dark mode */
    @media (prefers-color-scheme: dark) {
    body {
        background-color: black;
    }
    }
`;

export default GlobalStyle;
