const colors = [
  { r: 255, g: 0, b: 0, a: 1 }, // rgba(255,0,0,1)
  { r: 255, g: 0, b: 100, a: 1 }, // rgba(255,0,100,1)
  { r: 255, g: 0, b: 255, a: 1 }, // rgba(255,0,255,1)
  { r: 255, g: 100, b: 0, a: 1 }, // rgba(255,100,0,1)
  { r: 255, g: 100, b: 100, a: 1 }, // rgba(255,100,100,1)
  { r: 255, g: 100, b: 255, a: 1 }, // rgba(255,100,255,1)
  { r: 255, g: 255, b: 0, a: 1 }, // rgba(255,255,0,1)
  { r: 255, g: 255, b: 100, a: 1 }, // rgba(255,255,100,1)
  // no white
  { r: 100, g: 0, b: 0, a: 1 }, // rgba(100,0,0,1)
  { r: 100, g: 0, b: 100, a: 1 }, // rgba(100,0,100,1)
  { r: 100, g: 0, b: 255, a: 1 }, // rgba(100,0,255,1)
  { r: 100, g: 50, b: 0, a: 1 }, // rgba(100,50,0,1)
  // no grey
  { r: 100, g: 100, b: 255, a: 1 }, // rgba(100,100,255,1)
  { r: 100, g: 255, b: 0, a: 1 }, // rgba(100,255,0,1)
  { r: 100, g: 255, b: 100, a: 1 }, // rgba(100,255,100,1)
  { r: 100, g: 255, b: 255, a: 1 }, // rgba(100,255,255,1)
  // no black
  { r: 0, g: 0, b: 100, a: 1 }, // rgba(0,0,100,1)
  { r: 0, g: 0, b: 255, a: 1 }, // rgba(0,0,255,1)
  { r: 0, g: 100, b: 0, a: 1 }, // rgba(0,100,0,1)
  // ugly { r: 0, g: 100, b: 100, a: 1 }, rgba(0,100,100,1)
  { r: 0, g: 100, b: 255, a: 1 }, // rgba(0,100,255,1)
  { r: 0, g: 255, b: 0, a: 1 }, // rgba(0,255,0,1)
  { r: 0, g: 255, b: 100, a: 1 }, // rgba(0,255,100,1)
  { r: 0, g: 255, b: 255, a: 1 }, // rgba(0,255,255,1)
];

export default function randomColor() {
  return colors[Math.floor(Math.random() * (colors.length - 1))];
}
