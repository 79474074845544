import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import CanvasEffect from '../components/canvasEffect';
import Error from '../components/error';

// TODO: no javascript availabe what then?

const NotFoundPage = (props) => (
  <>
    <Layout>
      <SEO
        context={{
          title: 'Error: oops',
          description: 'Something broke, please try again later.',
        }}
        language="en"
      />
      <CanvasEffect />
      <Error title="Something broke, please try again later." />
    </Layout>
  </>
);

export default NotFoundPage;
