/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ language, keywords, context }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author {
              name
              website
            }
            siteUrl
            screenshot
            themeColor
            geo {
              country
              region
              regionShort
            }
          }
        }
      }
    `
  );

  const metaDescription = context.description
    ? context.description
    : site.siteMetadata.description;
  const metaTitle = context.title ? context.title : site.siteMetadata.title;

  return (
    <Helmet
      // TODO: useful for a offline bug
      // defer={false}
      htmlAttributes={{
        lang: language,
      }}
      defaultTitle={site.siteMetadata.title}
      title={metaTitle}
      script={[
        // strucured data: https://json-ld.org/
        {
          type: 'application/ld+json',
          innerHTML: `{ "@context": "http://schema.org", "@type": "Person", "logo": "https://www.joeyvandijk.nl/logo.png", "jobTitle": "Developer", "name": "Say Yup", "url": "https://www.joeyvandijk.nl", "image": "${site.siteMetadata.siteUrl}/${site.siteMetadata.screenshot}" }`,
        },
        {
          type: 'application/ld+json',
          innerHTML: `{ "@context": "http://schema.org", "@type": "WebSite", "name": "${metaTitle}", "url": "https://www.joeyvandijk.nl/" }`,
        },
      ]}
      link={[
        { rel: 'canonical', href: site.siteMetadata.siteUrl },
        // http://humanstxt.org/Standard.html
        {
          rel: 'image_src',
          href: `${site.siteMetadata.siteUrl}/${site.siteMetadata.screenshot}`,
        },
        {
          rel: 'preconnect',
          href: 'https://www.google-analytics.com',
        },
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `author`,
          content: `${site.siteMetadata.author.name} | ${site.siteMetadata.author.website}`,
        },
        {
          name: `theme-color`,
          content: site.siteMetadata.themeColor,
        },
        {
          name: `geo.country`,
          content: site.siteMetadata.geo.country,
        },
        {
          name: `geo.region`,
          content: site.siteMetadata.geo.region,
        },
        {
          name: `copyright`,
          content: `&copy; 2015 Say Yup B.V.`,
        },
        {
          name: `distribution`,
          content: `Global`,
        },
        {
          name: `identifier-url`,
          content: `https://www.joeyvandijk.nl`,
        },
        // prevent auto translation: https://support.google.com/webmasters/answer/79812?hl=en
        {
          name: `google`,
          content: `notranslate`,
        },
        // do not autoscale
        {
          name: `HandheldFriendly`,
          content: `true`,
        },
        // Add to home screen https://developer.chrome.com/multidevice/android/installtohomescreen
        {
          name: `mobile-web-app-capable`,
          content: `yes`,
        },
        // define how Safari can go full-screen on this site
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-title`,
          content: metaTitle,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `black`,
        },
        // open graph:
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:region`,
          content: site.siteMetadata.geo.regionShort,
        },
        {
          property: `og:country-name`,
          content: site.siteMetadata.geo.country,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        //    ~1200 x 627 pixels, as most display at a 1.91/1 ratio
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}/${site.siteMetadata.screenshot}`,
        },
        {
          property: `og:locale`,
          content: `nl_NL`,
        },
        //    main site name which will be the same on all pages
        {
          property: `og:site_name`,
          content: metaTitle,
        },
        //    approved URL for this page TODO: how to push to this component? while it can differ per page!
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        // twitter information:
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `${site.siteMetadata.author.name} - ${site.siteMetadata.author.website}`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author.twitter,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image:src`,
          content: `${site.siteMetadata.siteUrl}/${site.siteMetadata.screenshot}`,
        },
        {
          name: `twitter:image:alt`,
          content: language.toLowerCase() === 'en' ? 'photo' : 'foto',
        },
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : []
      )}
    />
  );
}

SEO.defaultProps = {
  language: `nl`,
  keywords: [],
  context: {},
};

SEO.propTypes = {
  context: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  language: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
};

export default SEO;
